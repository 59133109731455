export const ujumbedata = [
    {
        "title": "Kikao cha kwanza",
        "url": "https://archive.org/download/sharh-risalat/Ujumbe%20mfupi%20kuhusu%20saum-01-%5BNurein%20Ibn%20Uthman%5D.mp3"
    },
    {
        "title": "Kikao cha pili",
        "url": "https://archive.org/download/sharh-risalat/Ujumbe%20mfupi%20kuhusu%20saum-02-%5BNurein%20Ibn%20Uthman%5D.mp3"
    },
    {
        "title": "Kikao cha tatu",
        "url": "https://archive.org/download/sharh-risalat/Ujumbe%20mfupi%20kuhusu%20saum-03-%5BNurein%20Ibn%20Uthman%5D.mp3"
    },
    {
        "title": "Kikao cha nne",
        "url": "https://archive.org/download/sharh-risalat/Ujumbe%20mfupi%20kuhusu%20saum-04-%5BNurein%20Ibn%20Uthman%5D.mp3"
    }, {
        "title": "Kikao cha tano",
        "url": "https://archive.org/download/sharh-risalat/Ujumbe%20mfupi%20kuhusu%20saum-05-%5BNurein%20Ibn%20Uthman%5D.mp3"
    },
    {
        "title": "Kikao cha sita",
        "url": "https://archive.org/download/sharh-risalat/Ujumbe%20mfupi%20kuhusu%20saum-06-%5BNurein%20Ibn%20Uthman%5D.mp3"
    }, {
        "title": "Kikao cha saba",
        "url": "https://archive.org/download/sharh-risalat/Ujumbe%20mfupi%20kuhusu%20saum-07-%5BNurein%20Ibn%20Uthman%5D.mp3"
    },

]