export const asmaudata = [
   
    {
        "title": "Itikadi za mashia I",
        "url": "https://archive.org/download/mashia-kuhusiana-na-maimamu-wanne-wa-madhehebu-ya-ahlu-sunah-6/Itikadi%20za%20Mashia%201.mp3",
    },
    {
        "title": "Itikadi za mashia II",
        "url": "https://archive.org/download/mashia-kuhusiana-na-maimamu-wanne-wa-madhehebu-ya-ahlu-sunah-6/Itikadi%20za%20Mashia%202.mp3",
    },
    {
        "title": "Mashia kuhusiana na maimamu wa nne wa madhehebu ya Ahlu Sunnah I",
        "url": "https://archive.org/download/mashia-kuhusiana-na-maimamu-wanne-wa-madhehebu-ya-ahlu-sunah-6/Mashia%20Kuhusiana%20na%20Maimamu%20wanne%20wa%20madhehebu%20ya%20ahlu%20sunah%201.mp3",
    },
    {
        "title": "Mashia kuhusiana na maimamu wa nne wa madhehebu ya Ahlu Sunnah II",
        "url": "https://archive.org/download/mashia-kuhusiana-na-maimamu-wanne-wa-madhehebu-ya-ahlu-sunah-6/Mashia%20Kuhusiana%20na%20Maimamu%20wanne%20wa%20madhehebu%20ya%20ahlu%20sunah%202.mp3",
    },
    {
        "title": "Mashia kuhusiana na maimamu wa nne wa madhehebu ya Ahlu Sunnah III",
        "url": "https://archive.org/download/mashia-kuhusiana-na-maimamu-wanne-wa-madhehebu-ya-ahlu-sunah-6/Mashia%20Kuhusiana%20na%20Maimamu%20wanne%20wa%20madhehebu%20ya%20ahlu%20sunah%203.mp3",
    },
    {
        "title": "Mashia kuhusiana na maimamu wa nne wa madhehebu ya Ahlu Sunnah IV",
        "url": "https://archive.org/download/mashia-kuhusiana-na-maimamu-wanne-wa-madhehebu-ya-ahlu-sunah-6/Mashia%20Kuhusiana%20na%20Maimamu%20wanne%20wa%20madhehebu%20ya%20ahlu%20sunah%204.mp3",
    },
    {
        "title": "Mashia kuhusiana na maimamu wa nne wa madhehebu ya Ahlu Sunnah V",
        "url": "https://archive.org/download/mashia-kuhusiana-na-maimamu-wanne-wa-madhehebu-ya-ahlu-sunah-6/Mashia%20Kuhusiana%20na%20Maimamu%20wanne%20wa%20madhehebu%20ya%20ahlu%20sunah%205.mp3",
    },
    {
        "title": "Mashia kuhusiana na maimamu wa nne wa madhehebu ya Ahlu Sunnah VI",
        "url": "https://archive.org/download/mashia-kuhusiana-na-maimamu-wanne-wa-madhehebu-ya-ahlu-sunah-6/Mashia%20Kuhusiana%20na%20Maimamu%20wanne%20wa%20madhehebu%20ya%20ahlu%20sunah%206.mp3",
    },
    {
        "title": "Je ushia ni Uisilamu?",
        "url": "https://archive.org/download/mashia-kuhusiana-na-maimamu-wanne-wa-madhehebu-ya-ahlu-sunah-6/JE%20USHIA%20NI%20UISILAMU.mp3",
    },
    {
        "title": "Hukmu za ulamaa wa kisunnah kuhusu ushia",
        "url": "https://archive.org/download/mashia-kuhusiana-na-maimamu-wanne-wa-madhehebu-ya-ahlu-sunah-6/HUKMU%20ZA%20ULAMAA%20WA%20KISUNNI%20KUHUSU%20USHIA.mp3",
    },
   

]