export const hakami2021data = [
    {
        "title": "Darsa ya kwanza",
        "url": "https://archive.org/download/20240315_20240315_1239/%5B01%5D%20%D8%B5%D8%B1%D9%8A%D8%AD%20%D8%A7%D9%84%D8%B3%D9%86%D8%A9.mp3"
    },
    {
        "title": "Darsa ya pili",
        "url": "https://archive.org/download/20240315_20240315_1239/%5B02%5D%20%D8%B5%D8%B1%D9%8A%D8%AD%20%D8%A7%D9%84%D8%B3%D9%86%D8%A9.mp3"
    },
    {
        "title": "Darsa ya tatu",
        "url": "https://archive.org/download/20240315_20240315_1239/%5B03%5D%20%D8%B5%D8%B1%D9%8A%D8%AD%20%D8%A7%D9%84%D8%B3%D9%86%D8%A9.mp3"
    },
    {
        "title": "Darsa ya nne",
        "url": "https://archive.org/download/20240315_20240315_1239/%5B04%5D%20%D8%B5%D8%B1%D9%8A%D8%AD%20%D8%A7%D9%84%D8%B3%D9%86%D8%A9.mp3"
    },
    {
        "title": "Darsa ya tano",
        "url": "https://archive.org/download/20240315_20240315_1239/%5B05%5D%20%D8%B5%D8%B1%D9%8A%D8%AD%20%D8%A7%D9%84%D8%B3%D9%86%D8%A9.mp3"
    },
    {
        "title": "Kalima ya kufungwa kwa Dawrah",
        "url": "https://archive.org/download/20240315_20240315_1239/%D9%83%D9%84%D9%85%D8%A9_%D8%AE%D8%AA%D8%A7%D9%85%D9%8A%D8%A9_%D9%84%D8%AF%D9%88%D8%B1%D8%A9_%D8%A7%D9%84%D8%B4%D9%8A%D8%AE_%D8%AD%D8%A7%D9%81%D8%B8_%D8%A7%D9%84%D8%AD%D9%83%D9%85%D9%8A_%D9%84%D8%B9%D8%A7%D9%85%D9%87%D8%A7_%D8%A7%D9%84%D8%B3%D8%A7%D8%AF%D8%B3.mp3"
    },
    
]