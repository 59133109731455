export const mihadharadata = [
    {
        "title": "Mafunzo muhimu kwenye sera ya Imaam Shafiy",
        "url": "https://archive.org/download/mafunzo-muhimu-kwenye-sera-ya-imam-as-shafii/Mafunzo%20Muhimu%20Kwenye%20Sera%20Ya%20Imam%20As-Shafi%27i.mp3",
    },
    {
        "title": "Darsa ya kwanza",
        "url": "https://archive.org/download/mafunzo-muhimu-kwenye-sera-ya-imam-as-shafii/01-Bahjat%20Quluub%20al-Abraar.mp3",
    },
    {
        "title": "Darsa ya pili",
        "url": "https://archive.org/download/mafunzo-muhimu-kwenye-sera-ya-imam-as-shafii/02-Bahjat%20Quluub%20al-Abraar.mp3",
    },
    {
        "title": "Darsa ya tatu",
        "url": "https://archive.org/download/mafunzo-muhimu-kwenye-sera-ya-imam-as-shafii/03-Bahjat%20Quluub%20al-Abraar.mp3",
    },
   
]
