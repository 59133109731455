export const nyimbodata = [
    {
        "title": "Nyimbo, hukmu zake na athari zake",
        "url": "https://ia600205.us.archive.org/6/items/nyimbo-hukmu-na-athari-zake/Nyimbo%2C_hukmu_na_athari_zake.Mp3"
    },
    {
        "title": "Maswali na majibu kuhusu nyimbo",
        "url": "https://ia600205.us.archive.org/6/items/nyimbo-hukmu-na-athari-zake/Maswali_katika_mziki_na_athari_zake.mp3"
    },

]