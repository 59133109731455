export const nafsidata = [
    {
        "title": "Utangulizi",
        "url": "https://ia601305.us.archive.org/7/items/msingi_1/a%29_utangulizi.mp3"
    },
    {
        "title": "Msingi wa kwanza",
        "url": "https://ia601305.us.archive.org/7/items/msingi_1/msingi_1.mp3"
    },
    {
        "title": "Msingi wa pili",
        "url": "https://ia601305.us.archive.org/7/items/msingi_1/msingi_2.mp3"
    }, {
        "title": "Msingi wa watatu",
        "url": "https://ia601305.us.archive.org/7/items/msingi_1/msingi_3.mp3"
    }, {
        "title": "Msingi wa nne",
        "url": "https://ia601305.us.archive.org/7/items/msingi_1/msingi_4.mp3"
    }, {
        "title": "Msingi wa tano",
        "url": "https://ia601305.us.archive.org/7/items/msingi_1/msingi_5.mp3"
    }, {
        "title": "Msingi wa sita",
        "url": "https://ia601305.us.archive.org/7/items/msingi_1/msingi_6.mp3"
    }, {
        "title": "Msingi wa saba",
        "url": "https://ia601305.us.archive.org/7/items/msingi_1/msingi_7.mp3"
    },
    {
        "title": "Msingi wa nane",
        "url": "https://ia601305.us.archive.org/7/items/msingi_1/msingi_8.mp3"
    }, {
        "title": "Msingi wa tisa",
        "url": "https://ia601305.us.archive.org/7/items/msingi_1/msingi_9.mp3"
    }, {
        "title": "Msingi wa kumi",
        "url": "https://ia601305.us.archive.org/7/items/msingi_1/msingi_10.mp3"
    },

]