export const KenyaMapData = [
{"name": "Kwale",
"students": 5,
},

{
"name": "Mombasa",
"students": 95,
},

{"name": "Tharaka-Nithi",
"students": 5,
},

{
   "name": "Kilifi",
   "students": 5,
   },
{"name": "Tana River",
"students": 0,
},

{"name": "Lamu",
"students": 11,
},
 {"name": "Taita Taveta",
 "students": 9,
},
{"name": "Garissa",
"students": 7,
},
{"name": "Wajir",
"students": 1,
},  

{"name": "Mandera",
"students": 2,
},

{"name": "Marsabit",
"students": 5,
},
{"name": "Isiolo",
"students": 3,
}, 
{"name": "Meru",
"students": 4,
},
{"name": "Tharaka Nithi",
"students": 8,
},
{"name": "Embu",
"students": 12,
}, 
{"name": "Kitui",
"students": 54,
}, 
{"name": "Machakos",
"students": 32,
},    
{"name": "Makueni",
"students": 99,
}, 

{"name": "Nyandarua",
"students": 90,
}, 

{"name": "Nyeri",
"students": 21,
}, 
{"name": "Kirinyaga",
"students": 67,
},
{"name": "Murang'a",
"students": 89,
}, 

{"name": "Kiambu",
"students": 65,
}, 

{"name": "Turkana",
"students": 34,
},

{"name": "West Pokot",
"students": 57,
}, 

{"name": "Samburu",
"students": 78,
}, 

{"name": "Trans Nzoia",
"students": 51,
}, 

{"name": "Uasin Gishu",
"students": 34,
},  

{"name": "Elgeyo-Marakwet",
"students": 8,
},  

{"name": "Nandi",
"students": 3,
}, 

{"name": "Baringo",
"students": 45,
}, 
{"name": "Laikipia",
"students": 98,
},  

{"name": "Nakuru",
"students": 5,
},


{"name": "Narok",
"students": 62,
}, 

{"name": "Kajiado",
"students": 89,
},  

{"name": "Kericho",
"students": 32,
}, 

{"name": "Bomet",
"students": 43,
}, 

{"name": "Kakamega",
"students": 76,
}, 

{"name": "Vihiga",
"students": 11,
},

{"name": "Bungoma",
"students": 22,
},  

{"name": "Busia",
"students": 39,
},     

{"name": "Siaya",
"students": 60,
},   

{"name": "Kisumu",
"students": 79,
},    

{"name": "Homa Bay",
"students": 36,
}, 

{"name": "Migori",
"students": 91,
}, 

{"name": "Kisii",
"students": 42,
},

{"name": "Nyamira",
"students": 0,
}, 

{"name": "Nairobi",
"students": 94,
},

{"name": "Tanania",
"students": 4,
},

{"name": "Uganda",
"students": 9,
},

{"name": "Rwanda",
"students": 5,
},

{"name": "Burundi",
"students": 1,
},

{"name": "Democratic Republic of Congo",
"students": 0,
},
];
