import React from 'react'
import Footer from '../components/Footer'
function Quran() {
    return (
        <aside className="py-7 absolute mt-20">
            Coming soon!

            <div className='mt-20'>
            <div style={{ borderTop: '1px solid #000', marginBottom: '5px' }}></div>

                <Footer/>

            </div>
        </aside>
    )
}

export default Quran
