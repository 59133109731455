export const mihadharadata = [
   
    {
        "title": "Kikao cha kwanza",
        "url": "https://archive.org/download/1_20240321_20240321_0936/1.mp3",
    },
    {
        "title": "Kikao cha pili",
        "url": "https://archive.org/download/1_20240321_20240321_0936/2.mp3",
    },
    {
        "title": "Kikao cha nne",
        "url": "https://archive.org/download/1_20240321_20240321_0936/4.mp3",
    },
]

