export const eldamaravinedata = [
    {
        "title": "Kalima ya Kwanza",
        "url": "https://ia902806.us.archive.org/12/items/03_20240212_202402/KALIMAH.mp3",
    },
    {
        "title": "Kalima ya Pili",
        "url": "https://ia902806.us.archive.org/12/items/03_20240212_202402/KALIMAH2.mp3",
    },
    {
        "title": "Kalima ya Tatu",
        "url": "https://ia902806.us.archive.org/12/items/03_20240212_202402/KALIMAH3.mp3",
    },
    {
        "title": "Darsa ya Kwanza",
        "url": "https://ia902806.us.archive.org/12/items/03_20240212_202402/%5B01%5D%20%D8%A7%D9%84%D8%AA%D9%82%D8%B1%D9%8A%D8%A8%20%D9%84%D8%B3%D9%8A%D8%B1%D8%A9%20%D8%A7%D9%84%D8%AD%D8%A8%D9%8A%D8%A8.mp3",
    }, {
        "title": "Darsa ya Pili",
        "url": "https://ia902806.us.archive.org/12/items/03_20240212_202402/%5B02%5D%20%D8%A7%D9%84%D8%AA%D9%82%D8%B1%D9%8A%D8%A8%20%D9%84%D8%B3%D9%8A%D8%B1%D8%A9%20%D8%A7%D9%84%D8%AD%D8%A8%D9%8A%D8%A8.mp3",
    },
    {
        "title": "Darsa ya tatu na ya mwisho",
        "url": "https://ia802806.us.archive.org/12/items/03_20240212_202402/%5B03%5D%20%D8%A7%D9%84%D8%AA%D9%82%D8%B1%D9%8A%D8%A8%20%D9%84%D8%B3%D9%8A%D8%B1%D8%A9%20%D8%A7%D9%84%D8%AD%D8%A8%D9%8A%D8%A8.mp3",
    },
    
]