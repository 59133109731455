export const malindidata = [
    {
        "title": "Khutba ya idd fitr 1445",
        "url": "https://archive.org/download/1443_20240309/idd_fitr_1445.mp3",
    },
    {
        "title": "Khutba ya idd adh'ha 1444",
        "url": "https://archive.org/download/1443_20240309/%D8%AE%D8%B7%D8%A8%D8%A9%20%D8%B9%D9%8A%D8%AF%20%D8%A7%D9%84%D8%A3%D8%B6%D8%AD%D9%89%201444.mp3",
    },
    {
        "title": "Khutba ya idd fitr 1444",
        "url": "https://archive.org/download/1443_20240309/%D8%AE%D8%B7%D8%A8%D8%A9%20%D8%B9%D9%8A%D8%AF%20%D8%A7%D9%84%D9%81%D8%B7%D8%B1%201444.mp3",
    },
    {
        "title": "Khutba ya idd adh'ha 1443",
        "url": "https://archive.org/download/1443_20240309/%D8%AE%D8%B7%D8%A8%D8%A9%20%D8%B9%D9%8A%D8%AF%20%D8%A7%D9%84%D8%A3%D8%B6%D8%AD%D9%89%201443.mp3",
    },
    
    {
        "title": "Khutba ya idd fitr 1443",
        "url": "https://archive.org/download/1443_20240309/%D8%AE%D8%B7%D8%A8%D8%A9%20%D8%B9%D9%8A%D8%AF%20%D8%A7%D9%84%D9%81%D8%B7%D8%B1%201443.mp3",
    },
   
]