export const malindidata = [
    {
        "title": "Utangulizi",
        "url": "https://archive.org/download/03-ii_202403/%5B01%5D%20%D8%A7%D9%84%D9%85%D9%82%D8%AF%D9%85%D8%A9.mp3",
    },
    {
        "title": "Kikao cha kwanza",
        "url": "https://archive.org/download/03-ii_202403/%5B02%5D%20%D8%A8%D8%A7%D8%A8%20%D9%81%D8%B6%D9%84%20%D8%A7%D9%84%D8%A5%D8%B3%D9%84%D8%A7%D9%85%20%28I%29.mp3",
    },
    {
        "title": "Kikao cha pili",
        "url": "https://archive.org/download/03-ii_202403/%5B03%5D%20%D8%A8%D8%A7%D8%A8%20%D9%81%D8%B6%D9%84%20%D8%A7%D9%84%D8%A5%D8%B3%D9%84%D8%A7%D9%85%20%28II%29.mp3",
    },
    {
        "title": "Kikao cha tatu",
        "url": "https://archive.org/download/03-ii_202403/%5B04%5D%20%D8%A8%D8%A7%D8%A8%20%D9%88%D8%AC%D9%88%D8%A8%20%D8%A7%D9%84%D8%AF%D8%AE%D9%88%D9%84%20%D9%81%D9%8A%20%D8%A7%D9%84%D8%A5%D8%B3%D9%84%D8%A7%D9%85%20%28I%29.mp3",
    },
]