export const mihadharadata = [

    {
        "title": "Kikao cha kwanza",
        "url": "https://archive.org/download/2_20240322_202403/1.MP3",
    },
    {
        "title": "Kikao cha pili",
        "url": "https://archive.org/download/2_20240322_202403/2.mp3",
    },
   
    
]
