export const malindidata = [
    {
        "title": "Aya 1-4",
        "url": "https://archive.org/download/04-surah-al-mulk-ayah-18-25/%5B01%5D%20Surah%20Al-Mulk%20%28Ayah%201-4%29.mp3",
    },
    {
        "title": "Aya 5-12",
        "url": "https://archive.org/download/04-surah-al-mulk-ayah-18-25/%5B02%5D%20Surah%20Al-Mulk%20%28Ayah%205-12%29.mp3",
    },
    {
        "title": "Aya 13-17",
        "url": "https://archive.org/download/04-surah-al-mulk-ayah-18-25/%5B03%5D%20Surah%20Al-Mulk%20%28Ayah%2013-17%29.mp3",
    },
    {
        "title": "Aya ya 18-25",
        "url": "https://archive.org/download/04-surah-al-mulk-ayah-18-25/%5B04%5D%20Surah%20Al-Mulk%20%28Ayah%2018-25%29.mp3",
    },
    {
        "title": "Aya ya 26-30",
        "url": "https://archive.org/download/04-surah-al-mulk-ayah-18-25/%5B05%5D%20Surah%20Al-Mulk%20%28Ayah%2026-30%29.mp3",
    },
    
]