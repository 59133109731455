export const fadhlulmadinadat = [
    {
        "title": "Sifa za moto wa Jahannam",
        "url": "https://archive.org/download/2_20240322_20240322_1312/Sifa%20za%20Moto%20wa%20Jahanamu.mp3",
    },
    {
        "title": "Tarjama ya muandishi",
        "url": "https://archive.org/download/2_20240322_20240322_1312/1.mp3",
    },
    {
        "title": "Kikao cha pili",
        "url": "https://archive.org/download/2_20240322_20240322_1312/2.mp3",
    },
    
]