export const fadhlulmadinadat = [
    {
        "title": "Umuhimu wa kuhisi kuwa na Amana ya kidini",
        "url": "https://archive.org/download/umuhimu-wa-kuhisi-kuwa-na-amana-ya-kidini/Umuhimu%20wa%20Kuhisi%20Kuwa%20na%20Amana%20ya%20Kidini.mp3",
    },
    {
        "title": "Kikao cha kwanza",
        "url": "https://archive.org/download/umuhimu-wa-kuhisi-kuwa-na-amana-ya-kidini/01-%20Nuurul%20Baswaair.mp3",
    },
    {
        "title": "Kikao cha pili",
        "url": "https://archive.org/download/umuhimu-wa-kuhisi-kuwa-na-amana-ya-kidini/03-%20Nuurul%20Baswaair.mp3",
    },
    {
        "title": "Kikao cha tatu",
        "url": "https://archive.org/download/umuhimu-wa-kuhisi-kuwa-na-amana-ya-kidini/04-%20Nuurul%20Baswaair.mp3",
    },{
        "title": "Kikao cha nne",
        "url": "https://archive.org/download/umuhimu-wa-kuhisi-kuwa-na-amana-ya-kidini/05-%20Nuurul%20Baswaair.mp3",
    },
]

