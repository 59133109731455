export const qaraawi2021data = [
    {
        "title": "Kujihadhari na Magonjwa ya Moyo",
        "url": "https://ia600202.us.archive.org/27/items/05_20240309_202403/%D8%A7%D9%84%D8%AD%D8%B0%D8%B1%20%D9%85%D9%86%20%D8%A3%D9%85%D8%B1%D8%A7%D8%B6%20%D8%A7%D9%84%D9%82%D9%84%D9%88%D8%A8.mp3",
    },
    {
        "title": "Darsa ya Kwanza",
        "url": "https://ia600202.us.archive.org/27/items/05_20240309_202403/%5B01%5D%20%D8%A7%D9%84%D9%83%D9%81%D8%A7%D9%8A%D8%A9.mp3",
    },
    {
        "title": "Darsa ya Pili",
        "url": "https://ia600202.us.archive.org/27/items/05_20240309_202403/%5B02%5D%20%D8%A7%D9%84%D9%83%D9%81%D8%A7%D9%8A%D8%A9.mp3",
    },
    {
        "title": "Darsa ya Tatu",
        "url": "https://ia600202.us.archive.org/27/items/05_20240309_202403/%5B03%5D%20%D8%A7%D9%84%D9%83%D9%81%D8%A7%D9%8A%D8%A9.mp3",
    }, {
        "title": "Darsa ya Nne",
        "url": "https://ia800202.us.archive.org/27/items/05_20240309_202403/%5B04%5D%20%D8%A7%D9%84%D9%83%D9%81%D8%A7%D9%8A%D8%A9.MP3",
    },
    {
        "title": "Darsa ya tano na ya mwisho",
        "url": "https://ia600202.us.archive.org/27/items/05_20240309_202403/%5B05%5D%20%D8%A7%D9%84%D9%83%D9%81%D8%A7%D9%8A%D8%A9.mp3",
    },
    
]