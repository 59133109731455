export const asmaudata = [
   
    {
        "title": "Kikao cha kwanza",
        "url": "https://archive.org/download/fadhlu-sswiyaam-2/Fadhlu%20Sswiyaam%201.mp3",
    },
    {
        "title": "Kikao cha pili",
        "url": "https://archive.org/download/fadhlu-sswiyaam-2/Fadhlu%20Sswiyaam%202.MP3",
    },
    {
        "title": "Kikao cha tatu",
        "url": "https://archive.org/download/fadhlu-sswiyaam-2/Fadhlu%20Sswiyaam%203.MP3",
    },
    {
        "title": "Kikao cha nne",
        "url": "https://archive.org/download/fadhlu-sswiyaam-2/Fadhlu%20Sswiyaam%204.MP3",
    },
    {
        "title": "Kikao cha tano",
        "url": "https://archive.org/download/fadhlu-sswiyaam-2/Fadhlu%20Sswiyaam%205.mp3",
    },
    {
        "title": "Kikao cha sita",
        "url": "https://archive.org/download/fadhlu-sswiyaam-2/Fadhlu%20Sswiyaam%206.mp3",
    },
    {
        "title": "Kikao cha saba",
        "url": "https://archive.org/download/fadhlu-sswiyaam-2/Fadhlu%20Sswiyaam%207.mp3",
    },
    {
        "title": "Kikao cha nane",
        "url": "https://archive.org/download/fadhlu-sswiyaam-2/Fadhlu%20Sswiyaam%208.MP3",
    },
]