export const nairobidata = [
    {
        "title": "Fadhila ya Uisilamu",
        "url": "https://ia601307.us.archive.org/12/items/fadhla-ya-uislamu-na-sunnah/Fadhla_ya_Uislamu_na_sunnah.mp3"
    },
    {
        "title": "Kumfuata Mtume kuliko kamilika",
        "url": "https://ia601307.us.archive.org/12/items/fadhla-ya-uislamu-na-sunnah/Kumfuata_mtume_kuliko_kamilika.mp3"
    },
    {
        "title": "Manhajj ya Salaf na misingi yake",
        "url": "https://ia601307.us.archive.org/12/items/fadhla-ya-uislamu-na-sunnah/Manhaj_salaf_na_misingi_zake.mp3"
    },
]