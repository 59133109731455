export const raziyeindata = [
    

    {
        "title": "Darsa ya kwanza",
        "url": "https://archive.org/download/20240315_20240315_1239/%5B01%5D%20%D8%B5%D8%B1%D9%8A%D8%AD%20%D8%A7%D9%84%D8%B3%D9%86%D8%A9.mp3"
    },
    {
        "title": "Darsa ya pili",
        "url": "https://archive.org/download/20240315_20240315_1239/%5B02%5D%20%D8%B5%D8%B1%D9%8A%D8%AD%20%D8%A7%D9%84%D8%B3%D9%86%D8%A9.mp3"
    },
    {
        "title": "Darsa ya tatu",
        "url": "https://archive.org/download/20240315_20240315_1239/%5B03%5D%20%D8%B5%D8%B1%D9%8A%D8%AD%20%D8%A7%D9%84%D8%B3%D9%86%D8%A9.mp3"
    },
    {
        "title": "Darsa ya nne",
        "url": "https://archive.org/download/20240315_20240315_1239/%5B04%5D%20%D8%B5%D8%B1%D9%8A%D8%AD%20%D8%A7%D9%84%D8%B3%D9%86%D8%A9.mp3"
    },
    {
        "title": "Darsa ya tano",
        "url": "https://archive.org/download/20240315_20240315_1239/%5B05%5D%20%D8%B5%D8%B1%D9%8A%D8%AD%20%D8%A7%D9%84%D8%B3%D9%86%D8%A9.mp3"
    },
]

