export const asmaudata = [
    {
        "title": "Utangulizi na Tarjama ya Muandishi",
        "url": "https://archive.org/download/sharh-risalat/Sharh%20Asma-ul-Husna%20%2801%29.mp3",
    },
    {
        "title": "Darsa ya Kwanza",
        "url": "https://archive.org/download/sharh-risalat/Sharh%20Asma-ul-Husna%20%2802%29.mp3",
    },

]