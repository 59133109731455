import { useState, useEffect } from 'react';

const Visits = () => {
 const [pageviews, setPageviews] = useState(0);
 const [visits, setVisits] = useState(0);

 useEffect(() => {
  // Retrieve data from local storage (if available)
  const storedPageviews = localStorage.getItem('pageviews');
  if (storedPageviews) {
    setPageviews(parseInt(storedPageviews)); // Parse stored value to integer
  }

  // Fetch data from backend
  fetch('https://visits-2.onrender.com/visits')
  
  .then(res => res.json())
    .then(data => {
      setVisits(data.visits);
      setPageviews(data.pageviews);
      console.log('Page views are:', data.pageviews);
      console.log('Visits are:', data.visits);

      // Update local storage with fetched data
      localStorage.setItem('pageviews', data.pageviews);
    })
    .catch(error => console.error('Error fetching data:', error));
}, []);

 return (
    <div>
      <button className='bg-green-500 font-semibold text-black py-2 px-4 rounded'> Visits: {pageviews} </button>
    </div>
 );
};

export default Visits;
