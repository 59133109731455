import React from 'react'

function Mawaidha() {
    return (
        <div>
            Mawaidha
        </div>
    )
}

export default Mawaidha
