export const mihadharadata = [
    {
        "title": "Janaiz 1-4",
        "url": "https://archive.org/download/bulugh-maram-jainaiz-14-20/Bulugh%20Maram%20%5Bjainaiz%201-4%5D.mp3",
    },
    {
        "title": "Janaiz 6-9",
        "url": "https://archive.org/download/bulugh-maram-jainaiz-14-20/Bulugh%20Maram%20%5Bjainaiz%206-9%5D.mp3",
    },
    
    {
        "title": "Janaiz 12-13",
        "url": "https://archive.org/download/bulugh-maram-jainaiz-14-20/Bulugh%20Maram%20%5Bjainaiz%2012-13%5D.mp3",
    },
    {
        "title": "Janaiz 14-20",
        "url": "https://archive.org/download/bulugh-maram-jainaiz-14-20/Bulugh%20Maram%20%5Bjainaiz%2014-20%5D.mp3",
    },{
        "title": "Janaiz 21-25",
        "url": "https://archive.org/download/bulugh-maram-jainaiz-14-20/Bulugh%20Maram%20%5Bjainaiz%2021-25%5D.mp3",
    },
]
