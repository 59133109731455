export const mihadharadata = [
    {
        "title": "Sifa za pepo",
        "url": "https://archive.org/download/sifa-za-pepo/Sifa%20za%20Pepo.mp3",
    },
    {
        "title": "Kikao cha kwanza",
        "url": "https://archive.org/download/sifa-za-pepo/1.mp3",
    },
    {
        "title": "Kikao cha pili",
        "url": "https://archive.org/download/sifa-za-pepo/2.mp3",
    },
   
]

