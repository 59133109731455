export const mihadharadata = [
    
    {
        "title": "Kujiandaa na mauti",
        "url": "https://archive.org/download/kujiandaa-na-mauti/Kujiandaa%20Na%20Mauti.mp3",
    },
    {
        "title": "Kikao cha kwanza",
        "url": "https://archive.org/download/kujiandaa-na-mauti/01-Manhaj%20as-Saalikiin-Kitabbu%20nnikah.mp3",
    },
    {
        "title": "Kikao cha pili",
        "url": "https://archive.org/download/kujiandaa-na-mauti/02-Manhaj%20as-Saalikiin.mp3",
    },
    {
        "title": "Kikao cha nne",
        "url": "https://archive.org/download/kujiandaa-na-mauti/03-Manhaj%20as-Saalikiin.mp3",
    },
]

