export const asmaudata = [
   
    {
        "title": "Kikao cha kwanza",
        "url": "https://archive.org/download/1_20240325_20240325_0749/1.mp3",
    },
    {
        "title": "Kikao cha pili",
        "url": "https://archive.org/download/1_20240325_20240325_0749/2.mp3",
    },
    {
        "title": "Kikao cha tatu",
        "url": "https://archive.org/download/1_20240325_20240325_0749/3.mp3",
    },
]