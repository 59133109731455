export const mihadharadata = [
  
    {
        "title": "Darsa ya kwanza",
        "url": "https://archive.org/download/baad-tafri-abwaab-shahr-ramafhan-sunan-abi-daud./BAAD%20TAFRI%27%20ABWAAB%20SHAHR%20RAMAFHAN%20-%20SUNAN%20ABI%20DAUD..mp3",
    },
    {
        "title": "Darsa ya pili",
        "url": "https://archive.org/download/baad-tafri-abwaab-shahr-ramafhan-sunan-abi-daud./2.mp3",
    },
    {
        "title": "Darsa ya tatu",
        "url": "https://archive.org/download/baad-tafri-abwaab-shahr-ramafhan-sunan-abi-daud./1-1.mp3",
    },
    {
        "title": "Darsa ya nne",
        "url": "https://archive.org/download/baad-tafri-abwaab-shahr-ramafhan-sunan-abi-daud./3.mp3",
    },
]
