export const malindidata = [
    {
        "title": "Aya ya 1-5",
        "url": "https://archive.org/download/06-ayah-39-50_202403/%5B01%5D%20Ayah%201-5.mp3",
    },
    {
        "title": "Aya ya 6-15",
        "url": "https://archive.org/download/06-ayah-39-50_202403/%5B02%5D%20Ayah%206-15.mp3",
    },
    {
        "title": "Aya ya 16-21",
        "url": "https://archive.org/download/06-ayah-39-50_202403/%5B03%5D%20Ayah%2016-21.mp3",
    },
    {
        "title": "Aya ya 22-27",
        "url": "https://archive.org/download/06-ayah-39-50_202403/%5B04%5D%20Ayah%2022-27.mp3",
    }, {
        "title": "Aya ya 28-38",
        "url": "https://archive.org/download/06-ayah-39-50_202403/%5B05%5D%20Ayah%2028-38.mp3",
    },
    {
        "title": "Aya ya 39-50",
        "url": "https://archive.org/download/06-ayah-39-50_202403/%5B06%5D%20Ayah%2039-50.mp3",
    },
]