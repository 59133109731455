export const ijumaadara = [
    {
        "title": "Ubora wa mwezi wa Ramadhani Juu ya miezi zingine na jinsi alivyo kua Mtume  صلى الله عليه وسلم katika Ramadhani",
        "url": "https://archive.org/download/Ramadhan1440/Ramadhani%20fadhla%20zake%20na%20alivyokua%20mtume%20ndani%20yake.MP3",
    },
    
    {
        "title": "Neema za Allah juu yetu",
        "url": "https://ia600204.us.archive.org/19/items/manhaj-ya-ahlus-sunnah-wal-jamma-ah/Neema%20za%20Allah%20juu%20yetu.mp3",
    },
    {
        "title": "Kumi la mwisho la Ramadhan",
        "url": "https://archive.org/download/abu-ibrahim-al-amin/Kumi%20ya%20mwisho%20ndani%20ya%20Ramadhan.mp3",
    },
    
]