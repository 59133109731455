export const malindidata = [
    {
        "title": "Aya  1-5",
        "url": "https://archive.org/download/08-ayah-48-58_202403/%5B01%5D%20Ayah%201-5.mp3",
    },
    {
        "title": "Aya 6-11",
        "url": "https://archive.org/download/08-ayah-48-58_202403/%5B02%5D%20Ayah%206-11.mp3",
    },
    {
        "title": "Aya 12-20",
        "url": "https://archive.org/download/08-ayah-48-58_202403/%5B03%5D%20Ayah%2012-20.mp3",
    },
    {
        "title": "Aya 21-26",
        "url": "https://archive.org/download/08-ayah-48-58_202403/%5B04%5D%20Ayah%2021-26.mp3",
    }, {
        "title": "Aya 27-33",
        "url": "https://archive.org/download/08-ayah-48-58_202403/%5B05%5D%20Ayah%2027-33.mp3",
    },
    {
        "title": "Aya 34-40",
        "url": "https://archive.org/download/08-ayah-48-58_202403/%5B06%5D%20Ayah%2034-40.mp3",
    },
    {
        "title": "Aya 41-47",
        "url": "https://archive.org/download/08-ayah-48-58_202403/%5B07%5D%20Ayah%2041-47.mp3",
    },
    {
        "title": "Aya 48-58",
        "url": "https://archive.org/download/08-ayah-48-58_202403/%5B08%5D%20Ayah%2048-58.mp3",
    },
    {
        "title": "Aya 59-70",
        "url": "https://archive.org/download/08-ayah-48-58_202403/%5B09%5D%20Ayah%2059-70.mp3",
    }, {
        "title": "Aya 71-88",
        "url": "https://archive.org/download/08-ayah-48-58_202403/%5B10%5D%20Ayah%2071-88.mp3",
    },
   
]