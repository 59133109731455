export const QawaidArbaa3data = [
    {
        "title": "Utangulizi  I",
        "url": "https://archive.org/download/02-ii/%5B01%5D%20%D8%A7%D9%84%D9%85%D9%82%D8%AF%D9%85%D8%A9%20%28I%29.mp3",
    },
    {
        "title": "Msingi wa kwanza",
        "url": "https://archive.org/download/02-ii/%5B02%5D%20%D8%A7%D9%84%D9%85%D9%82%D8%AF%D9%85%D8%A9%20%28II%29%D8%8C%20%D8%A7%D9%84%D9%82%D8%A7%D8%B9%D8%AF%D8%A9%20%D8%A7%D9%84%D8%A3%D9%88%D9%84%D9%89.mp3",
    },
    {
        "title": "Msingi wa pili",
        "url": "https://archive.org/download/02-ii/%5B03%5D%20%D8%A7%D9%84%D9%82%D8%A7%D8%B9%D8%AF%D8%A9%20%D8%A7%D9%84%D8%AB%D8%A7%D9%86%D9%8A%D8%A9.mp3",
    },
    {
        "title": "Msingi wa tatu",
        "url": "https://archive.org/download/02-ii/%5B04%5D%20%D8%A7%D9%84%D9%82%D8%A7%D8%B9%D8%AF%D8%A9%20%D8%A7%D9%84%D8%AB%D8%A7%D9%84%D8%AB%D8%A9.mp3",
    },  {
        "title": "Msingi wa nne",
        "url": "https://archive.org/download/02-ii/%5B05%5D%20%D8%A7%D9%84%D9%82%D8%A7%D8%B9%D8%AF%D8%A9%20%D8%A7%D9%84%D8%B1%D8%A7%D8%A8%D8%B9%D8%A9.mp3",
    },
    
]
