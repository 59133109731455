export const malindidata = [
    {
        "title": "Aya 1-4",
        "url": "https://archive.org/download/07-surah-al-qalam-ayah-5-16/%5B06%5D%20Surah%20Al-Qalam%20%28Ayah%201-4%29.mp3",
    },
    {
        "title": "Aya 5-16",
        "url": "https://archive.org/download/07-surah-al-qalam-ayah-5-16/%5B07%5D%20Surah%20Al-Qalam%20%28Ayah%205-16%29.mp3",
    },
    {
        "title": "Aya 17-33",
        "url": "https://archive.org/download/qalama-17-33/Qalama17-33.mp3",
    },
    {
        "title": "Aya 34-45",
        "url": "https://archive.org/download/qalama-17-33/Qalama34-45.mp3",
    },
    {
        "title": "Aya 46-52",
        "url": "https://archive.org/download/qalama-17-33/Qalama46-52.mp3",
    },
    
]