export const fadhlulmadinadat = [
    
    {
        "title": "Kikao cha kwanza",
        "url": "https://archive.org/download/05-al-kifaayah/01-Al-Kifaayah.mp3",
    },
    {
        "title": "Kikao cha pili",
        "url": "https://archive.org/download/05-al-kifaayah/02-Al-Kifaayah.mp3",
    },
    {
        "title": "Kikao cha tatu",
        "url": "https://archive.org/download/05-al-kifaayah/03-Al-Kifaayah.mp3",
    },{
        "title": "Kikao cha nne",
        "url": "https://archive.org/download/05-al-kifaayah/04-Al-Kifaayah.MP3",
    },
    {
        "title": "Kikao cha tano",
        "url": "https://archive.org/download/05-al-kifaayah/05-Al-Kifaayah.mp3",
    },
]

