export const mihadharadata = [
    {
        "title": "Kuwatenda wema wazazi",
        "url": "https://archive.org/download/1_20240322_202403/Kuwatendea%20Wema%20Wazazi.MP3",
    },
    {
        "title": "Darsa ya kwanza",
        "url": "https://archive.org/download/1_20240322_202403/1.MP3",
    },
  
    {
        "title": "Darsa ya pili",
        "url": "https://archive.org/download/1_20240322_202403/2.mp3",
    },
]
