export const malindidata = [
    {
        "title": "Utangulizi na msingi wa kwanza",
        "url": "https://archive.org/download/01_20240319_202403/%5B01%5D%20%D8%A7%D9%84%D9%85%D9%82%D8%AF%D9%85%D8%A9%D8%8C%20%D8%A7%D9%84%D8%A3%D8%B5%D9%84%20%D8%A7%D9%84%D8%A3%D9%88%D9%84.mp3",
    },
    {
        "title": "Msingi wa pili na watatu",
        "url": "https://archive.org/download/01_20240319_202403/%5B02%5D%20%D8%A7%D9%84%D8%A3%D8%B5%D9%84%20%D8%A7%D9%84%D8%AB%D8%A7%D9%86%D9%8A%20%D9%88%D8%A7%D9%84%D8%AB%D8%A7%D9%84%D8%AB.mp3",
    },
    {
        "title": "Msingi wa nne",
        "url": "https://archive.org/download/01_20240319_202403/%5B03%5D%20%D8%A7%D9%84%D8%A3%D8%B5%D9%84%20%D8%A7%D9%84%D8%B1%D8%A7%D8%A8%D8%B9.mp3",
    },
    {
        "title": "Msingi wa tano na wa sita",
        "url": "https://archive.org/download/01_20240319_202403/%5B04%5D%20%D8%A7%D9%84%D8%A3%D8%B5%D9%84%20%D8%A7%D9%84%D8%AE%D8%A7%D9%85%D8%B3%20%D9%88%D8%A7%D9%84%D8%B3%D8%A7%D8%AF%D8%B3.mp3",
    },
    
]