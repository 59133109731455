export const links = [
    {
        id: 'radio',
        name: "Radio",
        link: '/radio',
        submenu: true,
        sublinks: [
            {
                id: 'quran',
                Head: "Qur'aan",
                link: '/quran',
            },
            {
                id: 'hadeeth',
                Head: "Hadeeth",
                link: '/hadeeth',
            },
            {
                id: 'mawaidha',
                Head: "Mawaidha",
                link: '/mawaidha',
            },
        ]
    },
];
