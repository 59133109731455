export const nawadhislamdata = [

    {
        "title": "Darsa ya kwanza ",
        "url": "https://ia601309.us.archive.org/18/items/nawaqidhul-islam-part-1-nurein-uthman/Nawaqidhul%20islam%20-%20Part%201_Nurein%20Uthman.mp4"
    },
    {
        "title": "Darsa ya pili",
        "url": "https://ia601309.us.archive.org/18/items/nawaqidhul-islam-part-1-nurein-uthman/Nawaqidhul%20islam%20-%20Part%202_Nurein%20Uthman.mp4"
    },



]