export const abuwaldyadata = [
    {
        "title": "Darsa ya kwanza",
        "url": "https://ia601305.us.archive.org/3/items/darsa_9_22-5-1442_5-1-2021/darsa_1_12-5-1442%2C26-12-2020.mp3"
    },
    {
        "title": "Darsa ya pili",
        "url": "https://ia601305.us.archive.org/3/items/darsa_9_22-5-1442_5-1-2021/darsa_2%2C_13-5-1442%2C_27-12-2020.mp3"
    },

    {
        "title": "Darsa ya tatu",
        "url": "https://ia601305.us.archive.org/3/items/darsa_9_22-5-1442_5-1-2021/darsa_3%2C_14-5-1442%2C_28-12-2020.mp3"
    },
    {
        "title": "Darsa ya nne",
        "url": "https://ia601305.us.archive.org/3/items/darsa_9_22-5-1442_5-1-2021/darsa_4%2C_15-5-1442%2C_29-12-2020.mp3"
    },
    {
        "title": "Darsa ya tano",
        "url": "https://ia601305.us.archive.org/3/items/darsa_9_22-5-1442_5-1-2021/darsa_5%2C_16-5-1442%2C_30-12-2020.mp3"
    },
    {
        "title": "Darsa ya sita",
        "url": "https://ia601305.us.archive.org/3/items/darsa_9_22-5-1442_5-1-2021/darsa_6%2C_19-5-1442%2C_2-1-2021.mp3"
    },
    {
        "title": "Darsa ya saba",
        "url": "https://ia601305.us.archive.org/3/items/darsa_9_22-5-1442_5-1-2021/darsa_7%2C_20-5-1442%2C_3-1-2021.mp3"
    },
    {
        "title": "Darsa ya nane",
        "url": "https://ia601305.us.archive.org/3/items/darsa_9_22-5-1442_5-1-2021/darsa_8%2C_21-5-1442%2C_4-1-2021.mp3"
    },
    {
        "title": "Darsa ya tisa",
        "url": "https://ia601305.us.archive.org/3/items/darsa_9_22-5-1442_5-1-2021/darsa_9%2C_22-5-1442%2C_5-1-2021.mp3"
    },

]