export const awesodata = [
    {
        "title": "Radd ya kwanza kuhusu nyiradi",
        "url": "https://ia600207.us.archive.org/7/items/02-radd-ya-uadilifu-kwa-aweso/%5B1%5D%20Ubaynifu%20wa%20talbiis%20za%20mubarak%20awes.mp3"
    },
    {
        "title": "Radd ya pili kuhusu nyiradi ",
        "url": "https://ia600207.us.archive.org/7/items/02-radd-ya-uadilifu-kwa-aweso/%5B2%5D%20Ubaynifu%20wa%20talbiis%20za%20mubaarak%20awes.mp3"
    },
    {
        "title": "Radd ya tatu kuhusu nyiradi",
        "url": "https://ia600207.us.archive.org/7/items/02-radd-ya-uadilifu-kwa-aweso/%5B3%5D%20Ubaynifu%20wa%20talbiis%20wa%20mubaarak%20awes.mp3"
    },
    {
        "title": "Radd ya pili kuhusu madrasa ya ahlu bayt",
        "url": "https://ia600207.us.archive.org/7/items/02-radd-ya-uadilifu-kwa-aweso/02-Radd%20ya%20uadilifu%20kwa%20Aweso.mp3"
    },
    {
        "title": "Radd ya nne kuhusu madrasa ya ahlu bayti",
        "url": "https://ia600207.us.archive.org/7/items/02-radd-ya-uadilifu-kwa-aweso/%5B4%5D%20Ubaynifu%20wa%20talbiis%20wa%20mubaarak%20awes.mp3"
    },
    {
        "title": "Radd ya tano kuhusu madrasa ya ahlu bayti",
        "url": "https://ia600207.us.archive.org/7/items/02-radd-ya-uadilifu-kwa-aweso/%5B5%5D%20Ubaynifu%20wa%20talbiis%20wa%20mubaarak%20awes.mp3"
    },
    {
        "title": "Radd ya sita kuhusu madrasa ya ahlu bayti",
        "url": "https://ia600207.us.archive.org/7/items/02-radd-ya-uadilifu-kwa-aweso/%5B6%5D%20%20Ubaynifu%20wa%20talbiis%20wa%20mubaarak%20awes.mp3"
    },
]